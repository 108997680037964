import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import CardForm from 'components/high_five/card_form'

class EditCard extends React.Component {

  render () {
    const { id, owner, recipient, dateCreated, dateSent, status,
            message, users, values } = this.props;

    const {
      passion: passion = passion || false,
      ownership: ownership = ownership || false,
      professionalism: professionalism = professionalism || false,
      trust: trust = trust || false,
      curiosity: curiosity = curiosity || false,
    } = values;

    return (
      <div>
        <CardForm
          title="Edit High Five Card"
          id={id}
          users={users}
          owner={owner}
          dateCreated={dateCreated}
          dateSent={dateSent}
          status={status}
          recipient={recipient}
          message={message}
          passion={passion}
          ownership={ownership}
          professionalism={professionalism}
          trust={trust}
          curiosity={curiosity}
          saveButtonTitle={'Update Card'}
          sendButtonTitle={'Send Card'}
        />
      </div>
    )
  }
}

export default EditCard
