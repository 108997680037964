import React, { Component } from 'react'
import axios from 'axios';
import { Select } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import Checkbox from 'components/shared/checkbox'

class CardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      owner: props.owner,
      recipient: props.recipient,
      dateCreated: props.dateCreated,
      dateSent: props.dateSent,
      status: props.status,
      message: props.message,
      users: props.users,
      passion: props.passion,
      ownership: props.ownership,
      professionalism: props.professionalism,
      trust: props.trust,
      curiosity: props.curiosity,
      saveButtonTitle: props.saveButtonTitle,
      sendButtonTitle: props.sendButtonTitle
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    let high_five_card = {
        recipient_id: this.state.recipient,
        message: this.state.message,
        values: {
          passion: this.state.passion,
          ownership: this.state.ownership,
          professionalism: this.state.professionalism,
          trust: this.state.trust,
          curiosity: this.state.curiosity,
        }
    };
    if (this.props.id) {
      axios.put(`/high_five_cards/${this.props.id}`, { high_five_card })
        .then(res=>{
          window.location.href = '/high_five_cards';
        })
    } else {
      axios.post(`/high_five_cards`, { high_five_card })
        .then(res=>{
          window.location.href = '/high_five_cards';
        })
    }
  }

  handleSend = event => {
    event.preventDefault();
    let high_five_card = {
        id: this.state.id,
        recipient_id: this.state.recipient,
        message: this.state.message,
        values: {
          passion: this.state.passion,
          ownership: this.state.ownership,
          professionalism: this.state.professionalism,
          trust: this.state.trust,
          curiosity: this.state.curiosity,
        }
    };

    axios.post(`/high_five_cards/${this.state.id}/send_mail`, { high_five_card })
      .then(res=>{
        window.location.href = '/high_five_cards';
      })
  }

  onChangeValue = () => {
    this.setState(initialState => ({
       [`${event.target.name}`]: !initialState[`${event.target.name}`]
    }))
  }

  onChangeMessage = (event) => {
    this.setState({ message: event.target.value });
  }

  onChangeRecipient = (event, data) => {
    this.setState({ recipient: data.value });
  }

  render () {
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="b-card__form>">
          <h2 className="b-mar-bottom">{this.state.title}</h2>
          <div className="form-group">
            { (this.state.id) &&
              <div className="b-mar-bottom b-preview-link">
                <a href={`/high_five_cards/${this.state.id}/view?target=_blank`}>Preview Card</a>
              </div>
            }
            <h4>Owner: {this.state.owner}</h4>
            { (this.state.dateCreated) &&
              <h4>Date Created: {this.state.dateCreated}</h4>
            }
            { this.state.status == 'Sent' && this.state.dateSent?
              <h4 className='b-mar-bottom'>Status: {this.state.status} on {this.state.dateSent}</h4> :
              <h4 className='b-mar-bottom'>Status: {this.state.status}</h4> }
            <div className='b-mar-top'>
              <h4>Recipient</h4>
              <Select
                id='recipient'
                className='form-control b-mar-bottom b-quarter-width'
                value={this.state.recipient}
                placeholder='Select Recipient'
                search
                selection
                options={this.state.users}
                onChange={this.onChangeRecipient}
              />
            </div>
            <div className='b-mar-bottom'>
              <h4>Values</h4>
              <div key='passion'>
                <Checkbox
                  name='passion'
                  checked={this.state.passion}
                  onChange={this.onChangeValue}
                />
                <label className='goals__label--share'>
                  <span>Passion</span>
                </label>
              </div>
              <div key='ownership'>
                <Checkbox
                  name='ownership'
                  checked={this.state.ownership}
                  onChange={this.onChangeValue}
                />
                <label className='goals__label--share'>
                  <span>Ownership</span>
                </label>
              </div>
              <div key='professionalism'>
                <Checkbox
                  name='professionalism'
                  checked={this.state.professionalism}
                  onChange={this.onChangeValue}
                />
                <label className='goals__label--share'>
                  <span>Professionalism</span>
                </label>
              </div>
              <div key='trust'>
                <Checkbox
                  name='trust'
                  checked={this.state.trust}
                  onChange={this.onChangeValue}
                />
                <label className='goals__label--share'>
                  <span>Trust</span>
                </label>
              </div>
              <div key='curiosity'>
                <Checkbox
                  name='curiosity'
                  checked={this.state.curiosity}
                  onChange={this.onChangeValue}
                />
                <label className='goals__label--share'>
                  <span>Curiosity</span>
                </label>
              </div>
            </div>
            <div className='form-group'>
              <h4>Message</h4>
              <textarea className='form-control b-half-width' rows='5' name='message' value={this.state.message} onChange={this.onChangeMessage}/>
            </div>
            <div>
              <input className='btn btn-primary b-mar-right' type='submit' value={this.state.saveButtonTitle}/>
              <input className='btn btn-info' type='button' value={this.state.sendButtonTitle} onClick={this.handleSend}/>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default CardForm
