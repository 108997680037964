import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios';

import EditCard from 'components/high_five/edit_card'
import CreateCard from 'components/high_five/create_card'

import 'styles/high_five_card.scss'

class HighFiveCard extends Component {
    constructor(props) {
    super(props);
      this.state = {
        card: props.card,
      }
  }

  componentDidMount() {
    this.setAjaxToken();
  }

  setAjaxToken() {
    let node = document.head.querySelector("[name=csrf-token]");
    if (node) {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = node.content;
    }
  }

  render () {
    return (
      this.props.id ?
        <div className="b-high_five_card">
          <EditCard
            id={this.props.id}
            owner={this.props.owner}
            recipient={this.props.recipient}
            dateCreated={this.props.dateCreated}
            dateSent={this.props.dateSent}
            status={this.props.status}
            values={this.props.values}
            message={this.props.message}
            users={this.props.users}
          />
      </div> :
      <div className="b-high_five_card">
        <CreateCard
          owner={this.props.owner}
          values={this.props.values}
          users={this.props.users}
        />
      </div>
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('high_five_card');
  if (!node) {
    return;
  }
  let owner = node.dataset.owner
  let recipient = node.dataset.recipient
  let dateCreated = node.dataset.createdAt
  let dateSent = node.dataset.dateSent
  let status = node.dataset.status
  let values = JSON.parse(node.dataset.values)
  let message = node.dataset.message
  let id = node.dataset.id
  let users = JSON.parse(node.dataset.users)

  ReactDOM.render(
    <HighFiveCard owner={owner} recipient={recipient} dateCreated={dateCreated} dateSent={dateSent} status={status} values={values} message={message} id={id} users={users}/>, node
  )
});


export default HighFiveCard
