import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import CardForm from 'components/high_five/card_form'

class CreateCard extends React.Component {

  render () {
    const { owner, recipient, users, status, message, dateCreated, dateSent, values } = this.props;

    const {
      passion: passion = passion || false,
      ownership: ownership = ownership || false,
      professionalism: professionalism = professionalism || false,
      trust: trust = trust || false,
      curiosity: curiosity = curiosity || false,
    } = values;

    return (
      <div>
        <CardForm
          title="Create New High Five Card"
          owner={owner}
          status={status}
          dateSent={dateSent}
          recipient={recipient}
          users={users}
          passion={passion}
          ownership={ownership}
          professionalism={professionalism}
          trust={trust}
          curiosity={curiosity}
          message={message}
          saveButtonTitle={'Save & Exit'}
          sendButtonTitle={'Save & Send Card'}
        />
      </div>
    )
  }
}

export default CreateCard
